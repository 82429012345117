var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataForm)?_c('b-modal',{ref:"modal-save-form-ref",attrs:{"title":"Çevresel Etki","ok-title":"Kaydet","cancel-title":"Kapat","cancel-variant":"outline-secondary","no-close-on-backdrop":"","no-enforce-focus":"","centered":"","size":"lg"},on:{"hidden":_vm.hiddenModal,"ok":_vm.handleOk}},[_c('validation-observer',{ref:"saveFormRef"},[_c('b-form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveForm($event)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Kodu"}},[_c('validation-provider',{attrs:{"rules":"required","name":"sKodu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.dataForm.sKodu),callback:function ($$v) {_vm.$set(_vm.dataForm, "sKodu", $$v)},expression:"dataForm.sKodu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1010200718)})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Adı"}},[_c('validation-provider',{attrs:{"rules":"required","name":"sAdi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.dataForm.sAdi),callback:function ($$v) {_vm.$set(_vm.dataForm, "sAdi", $$v)},expression:"dataForm.sAdi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1888215991)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Standart Adı"}},[_c('validation-provider',{attrs:{"rules":"required","name":"sStandartName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.dataForm.sStandartName),callback:function ($$v) {_vm.$set(_vm.dataForm, "sStandartName", $$v)},expression:"dataForm.sStandartName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3346160567)})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"contact-options","label-class":"mb-1"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.dataForm.isActive),callback:function ($$v) {_vm.$set(_vm.dataForm, "isActive", $$v)},expression:"dataForm.isActive"}},[_vm._v(" "+_vm._s(_vm.$t("Aktif Durumu"))+" ")])],1)])],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Max Su çimento Oranı"}},[_c('validation-provider',{attrs:{"rules":"required","name":"ldMaxSuCimentoOrani"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"min":"0","step":"any","type":"number"},model:{value:(_vm.dataForm.ldMaxSuCimentoOrani),callback:function ($$v) {_vm.$set(_vm.dataForm, "ldMaxSuCimentoOrani", $$v)},expression:"dataForm.ldMaxSuCimentoOrani"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,997614174)})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Dayanım Sınıfı"}},[_c('validation-provider',{attrs:{"rules":"required","name":"kL_BetonDayanimSinifId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.modalObject.betonDayanimSinifList,"label":"sAdi","reduce":function (val) { return val.id; }},model:{value:(_vm.dataForm.kL_BetonDayanimSinifId),callback:function ($$v) {_vm.$set(_vm.dataForm, "kL_BetonDayanimSinifId", $$v)},expression:"dataForm.kL_BetonDayanimSinifId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2719415405)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Çimento Min Miktar"}},[_c('validation-provider',{attrs:{"rules":"required","name":"ldCimentoMinMiktar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"min":"0","step":"any","type":"number"},model:{value:(_vm.dataForm.ldCimentoMinMiktar),callback:function ($$v) {_vm.$set(_vm.dataForm, "ldCimentoMinMiktar", $$v)},expression:"dataForm.ldCimentoMinMiktar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1507370549)})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Mineral Katkı Min Miktar"}},[_c('validation-provider',{attrs:{"rules":"required","name":"ldMineralKatkiMinMiktar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"min":"0","step":"any","type":"number"},model:{value:(_vm.dataForm.ldMineralKatkiMinMiktar),callback:function ($$v) {_vm.$set(_vm.dataForm, "ldMineralKatkiMinMiktar", $$v)},expression:"dataForm.ldMineralKatkiMinMiktar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2888305102)})],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }